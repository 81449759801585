import React from "react";
import "../assets/base.css";

function Sidenav(props: any) {
  return (
    <nav className={props.className}>
      <a className="sidenav__item responsive" href="/">
        Elizabeth
      </a>
      <a className="sidenav__item" href="#about">
        About
      </a>
      <a className="sidenav__item" href="#experience">
        Experience
      </a>
      <a className="sidenav__item" href="#education">
        Education
      </a>
      <a className="sidenav__item" href="#skills">
        Skills
      </a>
      <a className="sidenav__item" href="#contacts">
        Contacts
      </a>
    </nav>
  );
}

export default Sidenav;
