import React from "react";
// import coverImage from "./assets/cover_image.jpg";
import "./App.css";
import "./assets/reset.css";
import Sidenav from "./components/Sidenav";
import { HiOutlineMail } from "react-icons/hi";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";

function App() {
  return (
    <div className="App">
      <div id="cover" className="cover-image-wrapper">
        {/* <img src={coverImage} className="cover-image" alt="cover" /> */}
        <div className="cover-image-overlay">
          <div className="typing-text">Hello, my name is Elizabeth. </div>
          <p className="cover-subtitle">Welcome to my website!</p>
        </div>
      </div>

      <div className="content-wrapper">
        <Sidenav className="sidenav" />

        <div className="content">
          <section id="about" className="section">
            <div className="section__title">About</div>
            <p className="passage ">
              I have hands-on experience in software development in several
              projects and am familiar with various libraries and tools such as
              React, Next.js, AWS Lambda and PostgreSQL. I am enthusiastic about
              learning the latest technology and resilient to challenging
              fast-paced working environments.
            </p>
          </section>
          <hr />

          <section id="experience" className="section">
            <div className="section__title">Experience</div>

            <div className="subsection">
              <div className="row-space-between">
                <div className="subsection__title">Full Stack Developer</div>
                <div className="date">January 2022 – Present</div>
              </div>
              <div className="orgnization">Yzel (shopyzel.com)</div>
              <div className="passage passage--listed">
                <ul>
                  <li>Developed an ecommerce web application.</li>
                  <li>
                    Worked with modern frameworks such as React and Next.js as
                    well as cloud technologies like AWS Lambda.
                  </li>
                  <li>
                    Implemented GraphQL to interact with databases in Back4App.
                  </li>
                  <li>Incorporated payment feature using Stripe.</li>
                </ul>
              </div>
            </div>

            <div className="subsection">
              <div className="row-space-between">
                <div className="subsection__title">Junior AI Engineer</div>
                <div className="date">March 2021 – January 2022</div>
              </div>
              <div className="orgnization">Skymind</div>
              <div className="passage passage--listed">
                <ul>
                  <li>
                    Developed automation scripts in Python to do live image
                    processing for video captured by a camera and deployed a
                    trained AI model into an edge device to classify the images.
                  </li>
                  <li>
                    Developed a software application using Python and a SQL
                    database and integrated the trained AI model into the
                    application.
                  </li>
                  <li>
                    Developed the front end of a mobile application using React
                    Native.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <hr />

          <section id="education" className="section">
            <div className="section__title">Education</div>

            <div className="subsection">
              <div className="row-space-between">
                <div className="subsection__title">
                  Bachelor of Science in Physics
                </div>
                <div className="date">August 2016 – October 2020</div>
              </div>
              <div className="orgnization">University of Malaya</div>
              <p className="passage">CGPA: 3.86/4.00</p>
            </div>
          </section>
          <hr />

          <section id="skills" className="section">
            <div className="section__title">Skills</div>
            <p className="passage">
              JavaScript · HTML · CSS · Python · Git · React · React Native ·
              GraphQL· AWS Lambda · PostgreSQL · Back4App
            </p>
          </section>
          <hr />

          <section id="contacts" className="section">
            <div className="section__title">Contacts</div>
            <div className="contact__content">
              <a
                className="contact__item"
                href="https://www.linkedin.com/in/elizabeth-ng-xin-yi"
              >
                <FaLinkedin className="icon" />
                <p className="passage">
                  https://www.linkedin.com/in/elizabeth-ng-xin-yi
                </p>
              </a>

              <a className="contact__item" href="https://github.com/EllyNg65">
                <FaGithub className="icon" />
                <p className="passage">https://github.com/EllyNg65</p>
              </a>

              <div className="contact__item">
                <HiOutlineMail className="icon" />
                <p className="passage">elizabethngxinyi@gmail.com</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default App;
